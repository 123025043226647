.intro {
  padding: 150px 0 140px;
  position: relative;
  @include mobile {
    padding: 110px 0 100px;
  }
  &__desk {
    max-width: 460px;
  }
  &__title {
    font-family: var(--font-family-work);
    font-weight: 700;
    font-size: 58px;
    line-height: 130%;
    letter-spacing: -0.02em;
    margin-bottom: 10px;
    @include mobile {
      font-size: 42px;
      line-height: 120%;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 60px;
    @include mini-tablet {
      margin-bottom: 400px;
    }
    @include mobile {
      font-size: 16px;
      line-height: 150%;
    }
  }
  &__img {
    position: absolute;
    right: calc((100vw - 1675px) / 2);
    top: 0;
    width: 1021px;
    height: 869px;
    @include image-set("../img/intro__img");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    @include tablet {
      @include image-set("../img/intro__img-mob");
      width: 530px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 500px;
      background-size: cover;
    }
    @include mini-tablet {
      left: 0;
      right: auto;
      top: 47%;
    }
    @include mobile {
      width: 100%;
      background-position: center center;
      background-size: cover;
      top: 42%;
    }
  }
}

.bonus {
  position: relative;
  background: linear-gradient(265.39deg, #2412FF -8.32%, #25D7AC 89.02%);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 23px 20px 200px;
  width: 100%;
  @include mobile {
    padding: 20px 12px 20px 150px;
  }
  &::after {
    position: absolute;
    content: '';
    top: -26px;
    left: 0;
    width: 185px;
    height: 147px;
    background-image: url(../img/gift.png);
    background-repeat: no-repeat;
    background-size: cover;
    @include mobile {
      width: 145px;
      height: 145px;
      background-size: contain;
      top: auto;
      bottom: 0;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    span {
      font-weight: 700;
    }
    @include mobile {
      font-size: 16px;
      line-height: 150%;
    }
  }

}
