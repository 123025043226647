.section-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 130%;
  letter-spacing: -0.02em;
  margin-bottom: 50px;
  span {
    color: var(--blue-color);
  }
  @include mobile {
    font-size: 42px;
    line-height: 120%;
    margin-bottom: 40px;
  }
}
.title-center {
  text-align: center;
  @include mobile {
    text-align: left;
  }
}
