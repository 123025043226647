.receive {
  padding: 85px 0 135px;
  position: relative;
  background: linear-gradient(249.26deg, #F8F8F8 0%, #F4F4F4 100%);
  @include mobile {
    padding: 55px 0 100px;
  }
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 65px;
    background-color: var(--dark-color);
    left: 0;
    bottom: -1px;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    @include small-tablet {
      height: 40px;
    }
  }
  &__title {
    color: var(--dark-color)
  }
  &__content {
    max-width: 730px;
    margin: 0 auto;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
  }
  &__item {
    position: relative;
    width: 100%;
    background: var(--btn-color);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    border-radius: 20px;
    padding: 10px;
    @include small-tablet {
      min-height: 90px;
    }
    @include mobile {
      &:nth-child(3) {
        .receive__text {
          max-width: 245px;
          br {
            display: none;
          }
        }
      }
    }
    &::after {
      position: absolute;
      counter-increment: my-sec-counter 1;
      content: counter(my-sec-counter) ;
      font-family: var(--font-family-work);
      font-size: 105px;
      font-weight: 700;
      opacity: 0.2;
      color: var(--light-color);
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:last-child {
      background: var(--green-color);
      &::after {
        display: none;
      }
      @include mobile {
        .receive__text {
          max-width: 280px;
          br {
            display: none;
          }
        }
      }
    }
  }
  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    span {
      font-weight: 700;
    }
    @include small-tablet {
      font-size: 20px;
      line-height: 150%;
    }
  }
  &__link {
    color: var(--light-color);
    text-decoration: underline;
    transition: all .3s ease;
  }
}
