.awards {
  padding-bottom: 70px;
  @include high-tablet {
    .container {
      padding-right: 0px;
    }
  }
  &__slide {
    border: 1px solid transparent;
    background: linear-gradient(#161616 0 0) padding-box, linear-gradient(to right, rgba(211, 206, 206, 0.3),) border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 0 20px 40px 20px;
  }
  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    max-width: 152px;
    margin: 0 auto;
  }
  &__slider {
    position: relative;
    max-width: 992px;
    margin: 0 auto;
    padding-top: 35px;
    padding-bottom: 40px;
  }
  &__img {
    margin-bottom: 15px;
    text-align: center;
    margin-top: -50px;
  }
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0px;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: rgba(35, 138, 254, 0.5);
}
.swiper-pagination-bullet-active {
  width: 14px;
  height: 14px;
  background: var(--blue-color);
}
.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
