:root {
  --font-family: "Inter", sans-serif;
  --font-family-work: "Work Sans", sans-serif;
  --content-width: 1140px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #FAFAFA;
  --dark-color: #101010;
  --blue-color: #238AFE;
  --btn-color: linear-gradient(263.15deg, #238AFE -2.5%, #238AFE -2.49%, #2400FF 102.11%);
  --btn-hover: linear-gradient(263.15deg, #1a76df -2.5%, #1b7ae6 -2.49%, #1e02d6 102.11%);
  --card-color: #161616;
  --green-color: linear-gradient(263.15deg, #238AFE -2.5%, #00B828 -2.49%, #009E22 102.11%);
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterMedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterSemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url("../fonts/../fonts/WorkSansSemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  color: var(--light-color);
  background-color: var(--dark-color);
  counter-reset: my-sec-counter;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

html {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

b, strong {
  font-weight: 700;
}

a:hover, a:active, a:focus {
  color: inherit;
  text-decoration: none;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
}

a, li, button {
  outline: none !important;
}

.site-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  font-family: var(--font-family);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  min-width: 240px;
  max-height: 60px;
  color: var(--light-color);
  border-radius: 10px;
  background: var(--btn-color);
  transition: all 0.3s ease;
}
.btn:hover, .btn:active, .btn:focus {
  color: var(--light-color);
  background: var(--btn-hover);
}

.intro__btn {
  width: 100%;
}

.btn-get {
  max-height: 70px;
  padding: 25px 20px;
}

.form__btn {
  min-width: 216px;
  border: 2px solid #FAFAFA;
  background: transparent;
  grid-column: 2/3;
}
.form__btn:hover, .form__btn:active, .form__btn:focus {
  color: var(--light-color);
  background: var(--blue-color);
}
@media (max-width: 768px) {
  .form__btn {
    grid-column: 1/2;
  }
}

.section-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 130%;
  letter-spacing: -0.02em;
  margin-bottom: 50px;
}
.section-title span {
  color: var(--blue-color);
}
@media (max-width: 576px) {
  .section-title {
    font-size: 42px;
    line-height: 120%;
    margin-bottom: 40px;
  }
}

.title-center {
  text-align: center;
}
@media (max-width: 576px) {
  .title-center {
    text-align: left;
  }
}

.header {
  position: absolute;
  width: 100%;
  top: 20px;
  left: 0;
}
.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .header {
    top: 40px;
  }
}

.logo {
  display: inline-block;
}
@media (max-width: 768px) {
  .logo {
    margin-right: auto;
    width: 146px;
  }
}

.dropbtn {
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
  color: var(--light-color);
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 30px;
  background-color: var(--light-color);
  width: 80px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px;
  left: -25px;
}

.dropdown-content.active {
  display: block;
}

.dropbtn {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 14px 20px 14px 25px;
}

.dropbtn::after {
  background: rgba(0, 0, 0, 0) url("../img/arrow.svg") no-repeat scroll center center;
  content: "";
  height: 5px;
  position: absolute;
  right: 0;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.dropbtn-en {
  background: url("../img/en.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-vn {
  background: url("../img/vn.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-my {
  background: url("../img/my.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropdown-link {
  margin-bottom: 10px;
}
.dropdown-link:last-child {
  margin-bottom: 0;
}
.dropdown-link:hover {
  color: var(--dark-color);
}

.dropdown-link-en {
  background: url("../img/en.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-vn {
  background: url("../img/vn.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-my {
  background: url("../img/my.jpg") no-repeat left center;
  background-size: 23px 16px;
}

/* Links inside the dropdown */
.dropdown-link {
  padding-left: 25px;
  text-decoration: none;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark-color);
}

.intro {
  padding: 150px 0 140px;
  position: relative;
}
@media (max-width: 576px) {
  .intro {
    padding: 110px 0 100px;
  }
}
.intro__desk {
  max-width: 460px;
}
.intro__title {
  font-family: var(--font-family-work);
  font-weight: 700;
  font-size: 58px;
  line-height: 130%;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .intro__title {
    font-size: 42px;
    line-height: 120%;
  }
}
.intro__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
}
@media (max-width: 850px) {
  .intro__text {
    margin-bottom: 400px;
  }
}
@media (max-width: 576px) {
  .intro__text {
    font-size: 16px;
    line-height: 150%;
  }
}
.intro__img {
  position: absolute;
  right: calc((100vw - 1675px) / 2);
  top: 0;
  width: 1021px;
  height: 869px;
  background-image: url("../img/intro__img.png");
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background-image: image-set("../img/intro__img.avif" type("image/avif"), "../img/intro__img.webp" type("image/webp"), "../img/intro__img.jpg" type("image/jpg"));
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}
@media (max-width: 1024px) {
  .intro__img {
    background-image: url("../img/intro__img-mob.png");
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    background-image: image-set("../img/intro__img-mob.avif" type("image/avif"), "../img/intro__img-mob.webp" type("image/webp"), "../img/intro__img-mob.jpg" type("image/jpg"));
    width: 530px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 500px;
    background-size: cover;
  }
}
@media (max-width: 850px) {
  .intro__img {
    left: 0;
    right: auto;
    top: 47%;
  }
}
@media (max-width: 576px) {
  .intro__img {
    width: 100%;
    background-position: center center;
    background-size: cover;
    top: 42%;
  }
}

.bonus {
  position: relative;
  background: linear-gradient(265.39deg, #2412FF -8.32%, #25D7AC 89.02%);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 23px 20px 200px;
  width: 100%;
}
@media (max-width: 576px) {
  .bonus {
    padding: 20px 12px 20px 150px;
  }
}
.bonus::after {
  position: absolute;
  content: "";
  top: -26px;
  left: 0;
  width: 185px;
  height: 147px;
  background-image: url(../img/gift.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 576px) {
  .bonus::after {
    width: 145px;
    height: 145px;
    background-size: contain;
    top: auto;
    bottom: 0;
  }
}
.bonus__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
}
.bonus__text span {
  font-weight: 700;
}
@media (max-width: 576px) {
  .bonus__text {
    font-size: 16px;
    line-height: 150%;
  }
}

.advantages__wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .advantages__wrap {
    flex-wrap: wrap;
  }
}
.advantages__item {
  margin-right: 70px;
  max-width: 203px;
}
.advantages__item:nth-child(3) {
  margin-right: 50px;
  max-width: 167px;
}
@media (max-width: 576px) {
  .advantages__item:nth-child(3) {
    margin-right: 0px;
    max-width: 325px;
  }
}
@media (max-width: 1024px) {
  .advantages__item {
    margin-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .advantages__item {
    margin-right: 0px;
    max-width: 325px;
  }
}
.advantages__img {
  margin-bottom: 15px;
}
.advantages__head {
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  margin-bottom: 15px;
}
.advantages__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
}
.advantages__text span {
  font-weight: 700;
}
@media (max-width: 576px) {
  .advantages__text {
    font-size: 16px;
  }
}
.advantages__item-last {
  max-width: 240px;
  background: #212121;
  border: 0.3px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 8px #55D086, inset 0px 0px 3px rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding: 30px;
}
.advantages__item-last p {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
}
.advantages__item-last p span {
  font-weight: 700;
  color: var(--blue-color);
}
@media (max-width: 576px) {
  .advantages__item-last p {
    font-size: 18px;
    line-height: 150%;
  }
}
@media (max-width: 576px) {
  .advantages__item-last {
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .advantages__title {
    max-width: 185px;
  }
}

.get {
  text-align: center;
  padding: 130px 0 100px;
  background-image: url(../img/arrow-big.svg);
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 850px) {
  .get {
    background-image: url(../img/arrow-big-mob.svg);
    padding: 100px 0px;
  }
}
.get__wrap {
  padding: 105px 15px;
  background: radial-gradient(16.72% 50% at 50% 50%, rgba(62, 63, 67, 0.5) 0%, rgba(21, 22, 23, 0) 100%);
}
@media (max-width: 850px) {
  .get__wrap {
    padding: 100px 15px;
  }
}

.reasons {
  position: relative;
  padding-bottom: 230px;
  background: linear-gradient(359deg, #55D086 -0.98%, rgba(85, 208, 134, 0) 68.21%);
}
@media (max-width: 576px) {
  .reasons {
    padding-bottom: 70px;
  }
}
.reasons::after {
  position: absolute;
  content: "";
  bottom: -1px;
  left: 0;
  height: 50px;
  width: 100%;
  background: linear-gradient(249.26deg, #F8F8F8 0%, #F4F4F4 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
@media (max-width: 768px) {
  .reasons::after {
    height: 30px;
  }
}
.reasons__wrap {
  max-width: 750px;
  margin: 0 auto;
}
.reasons__title {
  text-align: center;
}
@media (max-width: 576px) {
  .reasons__title br {
    display: none;
  }
}
.reasons__content {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
}
@media (max-width: 768px) {
  .reasons__content {
    grid-template-columns: 1fr;
  }
}
.reasons__item {
  background: #161616;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 30px;
  border: 1px solid transparent;
  background: linear-gradient(#161616 0 0) padding-box, linear-gradient(to right, rgb(211, 206, 206), transparent) border-box;
}
.reasons__item:nth-child(1) {
  grid-column: 1/6;
}
@media (max-width: 768px) {
  .reasons__item:nth-child(1) {
    grid-column: 1/2;
  }
}
.reasons__item:nth-child(2) {
  grid-column: 6/9;
}
@media (max-width: 768px) {
  .reasons__item:nth-child(2) {
    grid-column: 1/2;
  }
}
.reasons__item:nth-child(3) {
  grid-column: 1/5;
}
@media (max-width: 768px) {
  .reasons__item:nth-child(3) {
    grid-column: 1/2;
  }
}
.reasons__item:nth-child(4) {
  grid-column: 5/9;
}
@media (max-width: 768px) {
  .reasons__item:nth-child(4) {
    grid-column: 1/2;
  }
}
.reasons__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}
.reasons__text span {
  color: var(--blue-color);
  font-weight: 700;
}
@media (max-width: 576px) {
  .reasons__text {
    font-size: 18px;
  }
}

.receive {
  padding: 85px 0 135px;
  position: relative;
  background: linear-gradient(249.26deg, #F8F8F8 0%, #F4F4F4 100%);
}
@media (max-width: 576px) {
  .receive {
    padding: 55px 0 100px;
  }
}
.receive::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 65px;
  background-color: var(--dark-color);
  left: 0;
  bottom: -1px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
@media (max-width: 768px) {
  .receive::after {
    height: 40px;
  }
}
.receive__title {
  color: var(--dark-color);
}
.receive__content {
  max-width: 730px;
  margin: 0 auto;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}
.receive__item {
  position: relative;
  width: 100%;
  background: var(--btn-color);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  border-radius: 20px;
  padding: 10px;
}
@media (max-width: 768px) {
  .receive__item {
    min-height: 90px;
  }
}
@media (max-width: 576px) {
  .receive__item:nth-child(3) .receive__text {
    max-width: 245px;
  }
  .receive__item:nth-child(3) .receive__text br {
    display: none;
  }
}
.receive__item::after {
  position: absolute;
  counter-increment: my-sec-counter 1;
  content: counter(my-sec-counter);
  font-family: var(--font-family-work);
  font-size: 105px;
  font-weight: 700;
  opacity: 0.2;
  color: var(--light-color);
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.receive__item:last-child {
  background: var(--green-color);
}
.receive__item:last-child::after {
  display: none;
}
@media (max-width: 576px) {
  .receive__item:last-child .receive__text {
    max-width: 280px;
  }
  .receive__item:last-child .receive__text br {
    display: none;
  }
}
.receive__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
}
.receive__text span {
  font-weight: 700;
}
@media (max-width: 768px) {
  .receive__text {
    font-size: 20px;
    line-height: 150%;
  }
}
.receive__link {
  color: var(--light-color);
  text-decoration: underline;
  transition: all 0.3s ease;
}

.trade {
  padding: 140px 0;
  background-image: url(../img/trade__bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 576px) {
  .trade {
    padding: 60px 0 80px;
  }
}
.trade__title {
  margin-bottom: 15px;
}
.trade__text {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  max-width: 590px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.trade__text span {
  font-weight: 600;
  color: var(--blue-color);
}
@media (max-width: 576px) {
  .trade__text {
    font-size: 18px;
    text-align: left;
  }
}
.trade__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 785px;
  margin: 0 auto;
}
.trade__item {
  background: var(--btn-color);
  box-shadow: 0px 0px 8px #237EFF;
  border-radius: 10px;
  min-height: 87px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.trade__item p {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}
.trade__item p span {
  font-weight: 700;
}
@media (max-width: 576px) {
  .trade__item {
    width: 100%;
  }
}

.awards {
  padding-bottom: 70px;
}
@media (max-width: 992px) {
  .awards .container {
    padding-right: 0px;
  }
}
.awards__slide {
  border: 1px solid transparent;
  background: linear-gradient(#161616 0 0) padding-box, linear-gradient(to right, rgba(211, 206, 206, 0.3)) border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 0 20px 40px 20px;
}
.awards__text {
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  max-width: 152px;
  margin: 0 auto;
}
.awards__slider {
  position: relative;
  max-width: 992px;
  margin: 0 auto;
  padding-top: 35px;
  padding-bottom: 40px;
}
.awards__img {
  margin-bottom: 15px;
  text-align: center;
  margin-top: -50px;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0px;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: rgba(35, 138, 254, 0.5);
}

.swiper-pagination-bullet-active {
  width: 14px;
  height: 14px;
  background: var(--blue-color);
}

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  position: relative;
  padding: 145px 0;
  background: var(--btn-color);
  z-index: 2;
}
@media (max-width: 576px) {
  .form {
    padding: 60px 0 50px;
  }
}
.form::after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  width: 755px;
  background-image: url(../img/form__bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
@media (max-width: 576px) {
  .form::after {
    width: 100%;
  }
}
.form__title {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 38px;
  line-height: 130%;
  letter-spacing: -0.02em;
}
@media (max-width: 576px) {
  .form__title {
    max-width: 245px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}
.form__label {
  width: 100%;
}
.form__input {
  width: 100%;
  background: var(--light-color);
  border: 1px solid rgba(82, 75, 71, 0.1);
  border-radius: 10px;
  height: 60px;
  padding: 18px 20px;
}
@media (max-width: 768px) {
  .form__input {
    max-width: 100%;
  }
}
.form__input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(82, 75, 71, 0.4);
}
.form__wrap {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 10px;
}
@media (max-width: 768px) {
  .form__wrap {
    grid-template-columns: 1fr;
    padding: 0 10px;
    gap: 20px;
  }
}

.footer {
  padding: 30px 0;
}
@media (max-width: 576px) {
  .footer {
    padding: 25px 0;
  }
}
.footer__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 576px) {
  .footer__wrap {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
@media (max-width: 576px) {
  .footer__link {
    margin-bottom: 15px;
  }
}

.copyright {
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  color: #FFFFFF;
}

.link {
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
}
.link:first-child {
  margin-right: 43px;
}