/* stylelint-disable */


@mixin big-desktop {
  @media (max-width: (1440px)) {
    @content;
  }
}



@mixin high-desktop {
  @media (max-width: (1200px)) {
    @content;
  }
}


@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin high-tablet {
  @media (max-width: (992px)) {
    @content;
  }
}

@mixin mini-tablet {
  @media (max-width: (850px)) {
    @content;
  }
}


@mixin small-tablet {
  @media (max-width: (768px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}
