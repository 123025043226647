// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Inter", sans-serif;
  --font-family-work: "Work Sans", sans-serif;
  --content-width: 1140px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #FAFAFA;
  --dark-color: #101010;
  --blue-color: #238AFE;
  --btn-color: linear-gradient(263.15deg, #238AFE -2.5%, #238AFE -2.49%, #2400FF 102.11%);

  --btn-hover: linear-gradient(263.15deg, #1a76df -2.5%, #1b7ae6 -2.49%, #1e02d6 102.11%);

  --card-color: #161616;
  --green-color: linear-gradient(263.15deg, #238AFE -2.5%, #00B828 -2.49%, #009E22 102.11%);
}
