.btn {
  font-family: var(--font-family);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  min-width: 240px;
  max-height: 60px;
  color: var(--light-color);
  border-radius: 10px;
  background: var(--btn-color);
  transition: all .3s ease;
  &:hover, &:active, &:focus {
    color: var(--light-color);
    background: var(--btn-hover);
  }
}
.intro__btn {
  width: 100%;
}

.btn-get {
  max-height: 70px;
  padding: 25px 20px;
}

.form__btn {
  min-width: 216px;
  border: 2px solid #FAFAFA;
  background: transparent;
  grid-column: 2 / 3;
  &:hover, &:active, &:focus {
    color: var(--light-color);
    background: var(--blue-color);
  }
  @include small-tablet {
    grid-column: 1 / 2;
  }
}
