.trade {
  padding: 140px 0;
  background-image: url(../img/trade__bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  @include mobile {
    padding: 60px 0 80px;
  }
  &__title {
    margin-bottom: 15px;
  }
  &__text {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    max-width: 590px;
    margin: 0 auto;
    margin-bottom: 50px;
    span {
      font-weight: 600;
      color: var(--blue-color);
    }
    @include mobile {
      font-size: 18px;
      text-align: left;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 785px;
    margin: 0 auto;
  }
  &__item {
    background: var(--btn-color);
    box-shadow: 0px 0px 8px #237EFF;
    border-radius: 10px;
    min-height: 87px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      span {
        font-weight: 700;
      }
    }
    @include mobile {
      width: 100%;
    }
  }
}
