.reasons {
  position: relative;
  padding-bottom: 230px;
  background: linear-gradient(359deg, #55D086 -0.98%, rgba(85, 208, 134, 0) 68.21%);
  @include mobile {
    padding-bottom: 70px;
  }
  &::after {
    position: absolute;
    content: '';
    bottom: -1px;
    left: 0;
    height: 50px;
    width: 100%;
    background: linear-gradient(249.26deg, #F8F8F8 0%, #F4F4F4 100%);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    @include small-tablet {
      height: 30px;
    }
  }
  &__wrap {
    max-width: 750px;
    margin: 0 auto;
  }
  &__title {
    text-align: center;
    @include mobile {
      br {
        display: none;
      }
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    @include small-tablet {
      grid-template-columns:  1fr;
    }
  }
  &__item {
    background: #161616;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 30px;
    border: 1px solid transparent;
    background: linear-gradient(#161616 0 0) padding-box, linear-gradient(to right, rgb(211, 206, 206), transparent) border-box;
    &:nth-child(1) {
      grid-column: 1 / 6;
      @include small-tablet {
        grid-column: 1 / 2;
      }
    }
    &:nth-child(2) {
      grid-column: 6 / 9;
      @include small-tablet {
        grid-column: 1 / 2;
      }
    }
    &:nth-child(3) {
      grid-column: 1 / 5;
      @include small-tablet {
        grid-column: 1 / 2;
      }
    }
    &:nth-child(4) {
      grid-column: 5 / 9;
      @include small-tablet {
        grid-column: 1 / 2;
      }
    }
  }
  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    span {
      color: var(--blue-color);
      font-weight: 700;
    }
    @include mobile {
      font-size: 18px;
    }
  }
}
