.advantages {
  &__wrap {
    display: flex;
    align-items: center;
    @include tablet {
      flex-wrap: wrap;
    }
  }
  &__item {
    margin-right: 70px;
    max-width: 203px;
    &:nth-child(3) {
      margin-right: 50px;
      max-width: 167px;
      @include mobile {
        margin-right: 0px;
        max-width: 325px;
      }
    }
    @include tablet {
      margin-bottom: 40px;
    }
    @include mobile {
      margin-right: 0px;
      max-width: 325px;
    }
  }
  &__img {
    margin-bottom: 15px;
  }
  &__head {
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    margin-bottom: 15px;
  }
  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    span {
      font-weight: 700;
    }
    @include mobile {
      font-size: 16px;
    }
  }
  &__item-last {
    max-width: 240px;
    background: #212121;
    border: 0.3px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 8px #55D086, inset 0px 0px 3px rgba(255, 255, 255, 0.25);
    border-radius: 20px;
    padding: 30px;
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      span {
        font-weight: 700;
        color: var(--blue-color);
      }
      @include mobile {
        font-size: 18px;
        line-height: 150%;
      }
    }
    @include mobile {
      max-width: 100%;
    }
  }
  &__title {
    @include mobile {
      max-width: 185px;
    }
  }
}

.get {
  text-align: center;
  padding: 130px 0 100px;
  background-image: url(../img/arrow-big.svg);
  background-repeat: no-repeat;
  background-position: center center;
  @include mini-tablet {
    background-image: url(../img/arrow-big-mob.svg);
    padding: 100px 0px;

  }
  &__wrap {
    padding: 105px 15px;
    background: radial-gradient(16.72% 50% at 50% 50%, rgba(62, 63, 67, 0.5) 0%, rgba(21, 22, 23, 0) 100%);
    @include mini-tablet {
      padding: 100px 15px;
    }
  }
}
