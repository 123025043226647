.footer {
  padding: 30px 0;
  @include mobile {
    padding: 25px 0;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  &__link {
    @include mobile {
      margin-bottom: 15px;
    }
  }
}
.copyright {
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  color: #FFFFFF;
}
.link {
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  &:first-child {
    margin-right: 43px;
  }
}
