.form {
  position: relative;
  padding: 145px 0;
  background: var(--btn-color);
  z-index: 2;
  @include mobile {
    padding: 60px 0 50px;
  }
  &::after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 755px;
    background-image: url(../img/form__bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    @include mobile {
      width: 100%;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 38px;
    line-height: 130%;
    letter-spacing: -0.02em;
    @include mobile {
      max-width: 245px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
  &__label {
    width: 100%;
  }
  &__input {
    width: 100%;
    background: var(--light-color);
    border: 1px solid rgba(82, 75, 71, 0.1);
    border-radius: 10px;
    height: 60px;
    padding: 18px 20px;
    @include small-tablet {
      max-width: 100%;
    }
    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(82, 75, 71, 0.4);
    }
  }
  &__wrap {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 10px;
    @include small-tablet {
      grid-template-columns: 1fr;
      padding: 0 10px;
      gap: 20px;
    }
  }
}
